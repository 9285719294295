<template>
  <div class="analytics-wrapper">
    <analytics-by-industry></analytics-by-industry>
  </div>
</template>

<script>
import AnalyticsByIndustry from '@/components/AnalyticsByIndustry'

export default {
  name: 'Analytics',
  components: {
    AnalyticsByIndustry
  },
}
</script>

<style lang="scss">

</style>