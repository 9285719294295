<template>
  <div class="analytics-by-industry-wrapper">

    <b-notification class="notification-wrapper" :active.sync="notification.show" :type="notification.type" auto-close>
      {{ notification.text }}
    </b-notification>

    <div class="component-title">
      {{ $t('analytics.industry_analytics') }}
    </div>

    <div class="params-wrapper">
      <div class="param-item-wrapper">
        <div class="param-title">
          Email
        </div>
        <b-field :type="errors.email ? 'is-danger' : 'text'">
          <b-input v-model="request_data.email" @input="errors.email = false"></b-input>
        </b-field>
        <div class="error-message" v-if="errors.email">
          {{ $t('analytics.invalid_email') }}
        </div>
      </div>

      <div class="param-item-wrapper">
        <b-switch 
          :value="request_data.active"
          v-model="request_data.active"
          type="is-success"
        >
          {{ $t('analytics.active_sites_only') }}
        </b-switch>
      </div>

      <div class="param-item-wrapper">
        <div class="param-title">
          {{ $t('period') }}
        </div>
        <b-field :type="errors.dates ? 'is-danger' : 'text'">
          <b-datepicker
            @input="errors.dates = false"
            :placeholder="$t('choose_period')"
            :date-formatter="dateFormat"
            v-model="dates"
            range>
          </b-datepicker>
        </b-field>
        <div class="error-message" v-if="errors.dates">
          {{ $t('choose_period') }}
        </div>
      </div>
    </div>

    <b-button class="send-button" :loading="isLoading" type="is-primary" @click="sendAnalytics">
      {{ $t('submit') }}
    </b-button>
  </div>
</template>

<script>
import * as moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

export default {
  name: 'AnalyticsByIndustry',
  data () {
    return {
      request_data: {
        email: '',
        active: false
      },
      dates: [],
      errors: {
        email: false,
        dates: false
      },
      isLoading: false,
      notification: {
        show: false,
        text: '',
        type: 'is-success'
      },
    }
  },
  methods: {
    dateFormat (dates) {
      dates = dates.map(function(date) {
        return moment(date).format('DD.MM.YYYY')
      })
      return dates.join(' - ')
    },
    validateEmail () {
      let match = this.request_data.email.match(this.$store.getters.regex.email)
      if(match && match.length > 0) {
        return true
      }
      else {
        return false
      }
    },
    validateData () {
      if(this.dates && this.dates.length === 2 && this.validateEmail()) {
        return true
      }
      else {
        if(!this.dates || this.dates.length < 2) {
          this.errors.dates = true
        }
        if(!this.validateEmail()) {
          this.errors.email = true
        }
        return false
      }
    },
    sendAnalytics () {
      if(this.validateData()) {
        this.isLoading = true
        this.$store.dispatch('sendAnalyticsByIndustry', {
          vm: this,
          callback: this.sendAnalyticsCallback
        })
      }
    },
    sendAnalyticsCallback (response, reject) {
      this.isLoading = false
      if(response === 'error') {
        this.notification.type = 'is-danger'
        this.notification.text = this.$t('analytics.analytics_request_error')
        this.notification.show = true
      }
      else {
        this.notification.type = 'is-success'
        this.notification.text = this.$t('analytics.analytics_sent_to_mail_successfully')
        this.notification.show = true
      }
    }
  }
}
</script>

<style lang="scss">
.analytics-by-industry-wrapper {
  border-radius: 4px;
  padding: 15px;
  border: 1px solid #dbdbdb;

  .notification-wrapper {
    position: fixed;
    top: 82px;
  }
  .component-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .params-wrapper {
    width: 350px;

    .param-item-wrapper {
      margin-bottom: 15px;

      .param-title {
        margin-bottom: 5px;
      }
    }
  }
  .send-button {
    margin-top: 5px;
  }
}
</style>